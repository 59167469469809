import React from 'react';

import Footer from './components/Footer';

import Header from './components/Header';

import LittleGame from './components/LittleGame';

import FollowedAccounts from './components/FollowedAccounts';

import DiscoverGrid from './components/DiscoverGrid';



function App() {

  return (

    <div className="bg-gray-100 min-h-screen flex flex-col">

      <Header />

      <div className="w-full md:max-w-md lg:max-w-lg xl:max-w-xl 2xl:max-w-3xl mx-auto flex-grow space-y-4">

        <LittleGame />

        <FollowedAccounts />

        <DiscoverGrid />

      </div>

      <Footer />

    </div>

  );

}



export default App;

